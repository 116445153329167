@import 'styles/includes';

.MyPagesFooter {
    $root: &;

    width: 100%;
    padding: 8rem 0 8.5rem;
    background-color: $colorBlue;
    border-radius: 8px 8px 0 0;
    color: $colorWhite;

    *:focus {
        outline-color: $colorYellow;
    }

    @include media(m) {
        padding: 85px 0 75px;
    }

    &__Container {
        @extend %container;
    }

    &__Inner {
        width: 100%;
        display: grid;
        gap: 2rem;

        @include media(s) {
            grid-template-columns: 1fr fit-content(12rem);
        }

        @include media(ml) {
            padding: 0 0 4rem;
            border-bottom: 1px solid $colorWhite;
            grid-template-columns: fit-content(15.2rem) 1fr fit-content(12rem);
            gap: 9.2rem;
        }
    }

    &__Logo {
        margin: 2rem auto 0 auto;
        width: 15.2rem;
        height: 4.5rem;
        display: block;
        background-image: url('/img/logo-jamtkraft-white.svg');
        background-size: contain;
        background-repeat: no-repeat;
        grid-column: -1/1;

        @include media(ml) {
            margin: 0;
            order: 0;
            grid-column: auto;
        }
    }

    &__Contact {
        @include media(ml) {
            order: 2;
        }
        @include media(m) {
            display: flex;
        }
    }

    &__ContactLabel {
        margin-bottom: 1rem;
        font-family: $fontBlack;
        font-weight: 900;
        font-size: 1rem;
        line-height: 2;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &__ContactList {
        &:last-child {
            margin-top: 12px;
        }

        @include media(m) {
            &:last-child {
                margin-top: 0;
                padding-left: 72px;
            }
        }
    }

    &__ContactItem {
        @extend %small;
        display: flex;
        line-height: 1.83rem;

        &:not(:last-child) {
            margin-bottom: 12px;

            @include media(s) {
                margin-bottom: 19px;
            }
        }
    }

    &__ContactInfo {
        text-decoration-line: underline;
        transition: text-decoration-color $transition;

        &:hover {
            text-decoration-color: transparent;
        }
    }

    &__Social {
        @include media-max(s) {
            &:last-child {
                margin-top: 20px;
            }
        }

        @include media(ml) {
            order: 3;
        }
    }

    &__SocialItem {
        display: flex;
        gap: 2.4rem;
    }

    &__SocialLabel {
        margin-bottom: 1rem;
        font-family: $fontBlack;
        font-weight: 900;
        font-size: 1rem;
        line-height: 2;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &__IconLink {
        color: $colorWhite;
    }
}
