@import 'styles/includes';

.HeroMyPages {
    $root: &;
    width: 100%;

    &__Container {
        padding: 2rem 0;
        background-image: linear-gradient(
            5deg,
            #fff8c4 2.97%,
            #f1f5f3 45.52%,
            #e2ebe6 94.77%
        );

        @include media(ml) {
            display: flex;
            padding: 0;
            min-height: 16.5rem;
        }
    }

    &__Inner {
        @extend %container;
        display: flex;
        flex-direction: column;

        @include media(ml) {
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
        }
    }

    &__Left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        @include media(ml) {
            display: grid;
            position: relative;
            padding: 3.25rem 0;
        }
    }

    &__Title {
        display: flex;
        align-items: center;
        color: $colorBlue;

        @include media(ml) {
            margin-bottom: 2rem;
        }
    }

    &__Icon {
        color: $colorBlue;
        margin-right: 1.2rem;
        width: 4rem;
        height: 4rem;

        @include media(ml) {
            margin-right: 2.4rem;
            width: 5rem;
            height: 5rem;
        }
    }

    &__Label {
        color: $colorBlue;
        font-size: 2.2rem;
        @extend %h3;

        @include media(ml) {
            font-size: 3.2rem;
        }
    }

    &__SingleAddress {
        color: $colorBlue;
        font-weight: 700;
    }

    &__Desktop {
        display: none;

        @include media(ml) {
            position: absolute;
            bottom: 0;
            display: block;
            align-self: end;
        }
    }

    &__Mobile {
        @extend %container;
        display: block;
        background-color: $colorWhite;
        padding-bottom: 3rem;
        padding-right: 1rem;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        @include media(ml) {
            display: none;
        }
    }

    &__Text {
        @extend %p;
        color: $colorContrast;
        margin-left: 7.4rem;
        margin-top: 0.4rem;
        display: none;

        @include media(ml) {
            display: block;
            margin-top: 0;
        }
    }

    &__Right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        gap: 1.8rem;
        min-width: 25.8rem;
    }

    &__PropertyId {
        font-size: 1.2rem;
        font-family: $fontBold;
        color: $colorBlue;
    }

    &__Tabs {
        margin-top: 2rem;

        @include media(ml) {
            margin-top: 0;
        }
    }

    &__List {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__Item {
        color: $colorBlue;
        margin: 0 15px;
        cursor: pointer;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            padding-right: 1.6rem;

            @include media(ml) {
                margin-right: 0;
                padding-right: 0;
            }
        }
    }

    &__Link {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 2.8rem;
        font-family: $fontBold;
        font-size: 1.6rem;
        font-weight: 600;
        color: $colorBlue;
        position: relative;
        background-color: transparent;
        display: inline-block;
        border: 0;
        border-radius: 0;

        &::after {
            content: '';
            height: 4px;
            width: 100%;
            background-color: transparent;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: $transition;
        }

        &--Active {
            &::after {
                background-color: $colorYellow;
            }
        }
    }
}
